<template>
  <div>
    <validation-observer ref="form">

      <empty-card
        :title="$t('business.info')"
      >
        <template slot="title">
          <div
            class="d-flex align-items-center"
          >
            <b-button
              :disabled="submitting"
              variant="dark"
              @click="submit()"
            >
              <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
              <b-spinner
                v-if="submitting"
                small
              />
            </b-button>
          </div>
        </template>

        <div class="mt-2">
          <error-alert
            v-if="error"
            :error="error"
          />
          <b-row class="align-items-center">
            <b-col
              class="mb-1"
              md="6"
            >
              <ok-text-input
                v-model="form.business_name"
                :label="$t('business.name')"
                is-required
                size="md"
              />
            </b-col>
            <b-col
              class="mb-1"
              md="6"
            >
              <ok-text-input
                v-model="form.slogan.ar"
                :label="`${$t('business.slogan')} (${$t(`locales.ar`)})`"
                size="md"
              />
            </b-col>
            <b-col
              class="mb-1"
              md="6"
            >
              <ok-text-input
                v-model="form.slogan.en"
                :label="`${$t('business.slogan')} (${$t(`locales.en`)})`"
                size="md"
              />
            </b-col>
            <b-col
              class="mb-1"
              md="6"
            >
              <ok-select
                v-model="form.currency"
                :label="$t('business.currency')"
                :options="currencies"
                is-required
                size="md"
              />
            </b-col>
            <b-col
              class="mb-1"
              md="6"
            >
              <ok-text-input
                :label="$t('business.phone')"
                is-required
                size="md"
              >
                <ok-phone-number-input v-model="form.phone" />
              </ok-text-input>
            </b-col>
            <b-col
              class="mb-1"
              md="6"
            >
              <ok-text-input
                :label="$t('business.locales')"
                size="md"
              >
                <localization-language-select
                  v-model="form.locales"
                  multiple
                  with-ui
                />
              </ok-text-input>
            </b-col>
          </b-row>
        </div>
      </empty-card>
    </validation-observer>

  </div>
</template>

<script>
import {
  BButton, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import ErrorHandler from '@/utils/error-handler'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import AuthApi from '@/api/auth'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import LocalizationLanguageSelect from '@/views/store/inputs/StoreLanguageSelect'
import { mapGetters } from 'vuex'
import OkPhoneNumberInput from '@/@bya3/components/inputs/OkPhoneNumberInput'
import OkSelect from '@/@bya3/components/inputs/OkSelect'

export default {
  name: 'ProcessBusinessInfoPage',
  components: {
    OkSelect,
    ErrorAlert,
    EmptyCard,
    BButton,
    OkPhoneNumberInput,
    LocalizationLanguageSelect,
    OkTextInput,
    ValidationObserver,
    BRow,
    BCol,
    BSpinner,
  },
  data() {
    return {
      submitting: false,
      isGeneralValid: true,
      form: {},
      error: null,
      currencies: [
        { label: this.$t('currencies.sp'), value: 'sp' },
        { label: '$', value: '$' },
        { label: '€', value: '€' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.form = {
        business_name: this.user.business_name,
        currency: this.user.currency,
        locales: this.user.store_locales,
        slogan: this.user.slogan ?? { ar: '', en: '' },
        phone: this.user.phone,
      }
      if (!this.form?.slogan?.ar && !this.form?.slogan?.en) {
        this.form.slogan = { ar: '', en: '' }
      }
    },
    async submit() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = {
          title: this.$tc('errors.field-invalid', 2),
          message: ErrorHandler.getValidationErrors(valid.errors),
        }
        return
      }
      const keys = ['phone', 'business_name', 'currency', 'slogan', 'locales']
      const payload = pick(this.form, keys)
      this.submitting = true
      AuthApi.update(payload)
        .then(response => {
          this.$notifySuccess(response.message)
          const res = { ...payload, store_locales: payload.locales }
          delete res.locales
          this.$store.commit('auth/UPDATE_USER', { ...res })
          setTimeout(() => this.init())
        })
        .catch(error => {
          this.error = error
        })
        .finally(() => {
          this.submitting = false
        })
    },
  },
}
</script>
